<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Inviter dans un groupe</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Inviter dans un groupe" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2">
        <form-category title="Liste de groupes" subtitle="Inviter l'utilisateur dans un de ces groupes" class="mt-3" />
        <v-list class="pa-0 " three-line>
          <teams-list-select v-model="teamUid" />
        </v-list>
        <div class="d-flex justify-center mt-5">
          <v-btn color="primary" x-small :loading="submitting" :disabled="submitting || loading" @click="submit">
            Inviter
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import TeamsListSelect from '@/modules/teams/components/TeamsListSelect'
  import {Team} from '@/models'

  export default {
    name: 'TeamInviteUserDialog',
    components: {ModalsSidebar, FormCategory, TeamsListSelect},
    props: {
      professionalUid: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        teamUid: null,
        loading: false,
        submitting: false
      }
    },
    methods: {
      async submit() {
        try {
          this.submitting = true
          await Team.get(this.teamUid).inviteList([this.professionalUid])

          this.$emit('close')
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Invitation envoyée',
            subtitle: 'L\'invitation a bien été envoyée.'
          })
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Le professionnel sélectionné n\'a pas pu être invité.'
          })
          throw e
        } finally {
          this.submitting = false
        }
      }
    }
  }
</script>
